import api from '../api'

/**
 * 提交人保订单
 */
export function submitPiccOrderService(param) {
  return api.post('/picc/order', param);
}

/**
 * 订单列表
 * @param param
 * @returns {Promise<AxiosResponse<T>>}
 */
export function ordersService(param) {
  return api.get('/orders', {
    params:param
  })
}

export function orderDetailService(orderNo) {
  return api.get(`/order/${orderNo}`);
}
